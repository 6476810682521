@import "../../styles/colors";

.disclosure-sensitive-info {
  overflow: hidden;
  position: relative;

  .app-logo {
    margin: 0 auto;
    display: block;
    width: fit-content;
    z-index: 3;
    position: relative;
    padding-top: 84px;

    img {
      width: auto;
      display: block;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .main-bg {
    opacity: .3;
    top: 0;
    left: initial;
    right: 50%;
    transform: rotate(180deg) translateX(-50%);
    width: 1550px;
    height: 1024px;
  }
}

.disclosure-sensitive-info-mid-area {
  max-width: 800px;
  position: relative;
  z-index: 3;
  margin: 80px auto 0 auto;
  width: 100%;
  flex: 1 0 auto;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  h1 {
    font-size: 40px;
    line-height: 120%;
    font-weight: 700;
    margin: 0 0 5px;
  }

  p, a, td {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $white;
    word-break: break-word;
  }

  ul, li {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $white;
  }

  .disclosure-sensitive-info-list-head-right, .disclosure-sensitive-info-content__point  {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;

    strong {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  table {
    margin-bottom: 20px;
    min-width: 700px;
  }

  .disclosure-sensitive-info-content__table_collect {
    overflow-x: auto;
  }

  table tr td {
    border: 1px solid #4a4a4a;

    p {
      margin-bottom: 0;
    }
  }

  td {
    padding: 5px;
  }

  .disclosure-sensitive-info-content__table_business-purpose {
    @media (max-width: 768px) {
      overflow-x: auto;
    }

    table {
      min-width: 550px;
    }

    table tr td {
      border: 0;

      @media (max-width: 768px) {
        border: 1px solid #4a4a4a;
      }
    }
  }
}