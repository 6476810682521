@import "../../styles/colors";
@import "../../styles/variables";

.homepage {
  overflow: hidden;
  position: relative;

  a {
    margin-left: auto;
    display: block;
    width: fit-content;
    z-index: 3;
    position: relative;

    img {
      width: auto;
      display: block;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .footer__links {

    a {
      @media (max-width: 768px) {
        margin-left: initial;
        display: inline;
      }
    }
  }

  &__wrapper {
    padding-top: 172px;

    @media (max-width: 768px) {
      padding-top: 30px;
    }

    &__content {
      margin-top: 80px;
      max-width: 671px;
      z-index: 3;
      position: relative;
      margin-left: auto;

      @media (max-width: 768px) {
        margin-top: 50px;
      }

      p {
        font-weight: 400;
        font-size: 38px;
        line-height: 47px;
        margin: 0 0 56px auto;
        color: $white;
        text-align: right;
        max-width: 463px;

        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 130%;
        }
      }

      a {
        text-decoration: none;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $prussian;
        font-size: 32px;
        padding: 30px 32px;
        background: $white;
        border-radius: 48px;
        line-height: 24px;
        font-weight: 400;
        margin-left: auto;
        font-family: $font-baloo-regular;

        @media (max-width: 768px) {
          border-radius: 28px;
          padding: 10px 15px;
          font-size: 28px;
        }
      }
    }
  }
}