@font-face {
    font-family: 'Atkinson';
    src: local('Montserrat'), local('Atkinson-Regular'), url('fonts/Atkison/AtkinsonHyperlegible-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Atkinson';
    src: local('Montserrat Bold'), local('Atkinson-Bold'), url('fonts/Atkison/AtkinsonHyperlegible-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Baloo';
    src: local('Montserrat SemiBold'), local('Baloo-Regular'), url('fonts/Baloo/Baloo-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}